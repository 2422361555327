.team-members {
	.team-member {
		position: relative;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 5rem;
	}

	.team-member__description {
		position: relative;
		width: 65%;
		padding: 3rem 0 2rem 6rem;
		background-image: $smd-grey-gradient-reverse;
	}

	.team-member__image {
		position: absolute;
		top: 0;
		left: 0;
		transform: translateX(-75%);
	}

	.team-member__title {
		position: absolute;
		top: 250px;
		left: 0;
		background-color: smd-color('dark');
		z-index: 99;
		padding: 1rem;

		h5 {
			font-weight: 300;
			font-size: 1.5rem;
			color: $white;
			margin: 0 0 .25rem 0;
		}

		h6 {
			text-transform: uppercase;
			color: smd-color('light-orange');
			font-weight: 500;
			font-size: 0.875rem;
			margin: 0;
		}
	}
}

@include media-breakpoint-down(md) {
	.team-members {
		.team-member {
			display: block;
			padding-top: 10rem;
		}

		.team-member__description {
			position: static;
			padding: 15rem 2rem 2rem 2rem;
			width: 100%;
		}

		.team-member__image {
			width: 200px;
			transform: none;
			top: 0;
			left: auto;
		}

		.team-member__title {
			left: 200px;
			top: 200px;
		}
	}
}