.callout--contact {
	.container {
		position: relative;
	}

	.callout__title {
		position: absolute;
		top: 50%;
		left: 1.25rem;
		width: 275px;
		z-index: 9;
		font-size: 2rem;
		font-weight: 300;
		font-style: italic;
		transform: translateY(-50%);
	}

	.callout__content {
		@extend .col-lg-10;
		@extend .offset-lg-2;
		@extend .col-12;
		@extend .py-lg-5;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background-color: smd-color('light-orange');
		padding-left: 12rem;

		@include media-breakpoint-down(sm) {
			display: block;
			padding-left: 15px;
		}

		p {
			@extend .mr-lg-4;
			@extend .mb-lg-0;
			@extend .mb-5;
		}

		a {
			@extend .btn;
			@extend .btn--dark;
			@extend .flex-shrink-0;
			@extend .mr-lg-5;
		}
	}
}

@include media-breakpoint-down(lg) {
	.callout--contact {
		background-color: smd-color('light-orange');
		padding-top: 2rem;
		padding-bottom: 2rem;

		.callout__title {
			position: static;
			transform: none;
			width: 100%;
			top: 0;
			padding-left: 15px;
			padding-right: 15px;
		}

		.callout__block {
			padding-left: 15px;
			flex-flow: column wrap;
		}
	}
}