body {
	&::before {
		@include animate;
    content: '';
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.9);
    z-index: 9998;
  }
}

body.menu-shown {
	overflow: hidden;

	&::before {
    visibility: visible;
    opacity: 1;
  }
}

#navigation--flyout__wrap {
	@include animate;
	position: fixed;
	top: 0;
	right: -50%;
	width: 50%;
	height: 100%;
	z-index: 9999;
	overflow-y: scroll;
	background-color: smd-color("light-orange");

	.btn--toggle-menu {
		position: -webkit-sticky;
		position: sticky;
		top: 15px;
		right: 15px;
		text-indent: -9999px;
		background-image: url('./assets/images/icon-close.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		width: 24px;
		height: 24px;
		display: inline-block;
		float: right;
	}

	.navigation--flyout {
		padding-top: 10%;
		padding-left: 3rem;
		padding-bottom: 10%;
		width: 100%;
		min-height: 100%;
	}

	a {
		color: smd-color("dark");
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	ul li a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	ul.menu {
		list-style-type: none;
		margin: 0;
		padding: 0;

		& > li > a {
			display: block;
			font-size: 1.5rem;
			font-weight: 700;
			font-style: italic;
			text-transform: uppercase;
			letter-spacing: 1px;
			padding: .5rem 0;
		}
	}

	ul.sub-menu {
		list-style-type: none;
		margin-left: 1rem;
		padding: 0;

		li a {
			display: block;
			font-weight: 300;
			padding: .5rem 0;
			font-style: italic;
		}
	}

	.form--search {
		position: relative;

		input[type="search"] {
			background-color: smd-color("dark-orange");
			border-radius: 500px;
			width: 100%;
			border: none;
			padding: 5px 5px 5px 32px;
			color: smd-color("dark");

			&::placeholder {
				color: smd-color("dark");
				font-style: italic;
				font-weight: 300;
			}
		}

		input[type="submit"] {
			width: 10%;
			position: absolute;
			left: 2%;
			top: 0;
			bottom: 0;
			background-image: url('./assets/images/icon-search.svg');
			background-size: 75%;
			background-position: center;
			background-repeat: no-repeat;
			background-color: transparent;
			border: none;
			text-indent: -9999px;
		}
	}

	.linkedin {
		display: block;
		width: 30px;
		height: 30px;
		background-image: url('./assets/images/icon-linkedin.svg');
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		text-indent: -9999px;
	}
}

#navigation--flyout__wrap.shown {
	right: 0;
}

@include media-breakpoint-down(md) {
	#navigation--flyout__wrap {
		width: 100%;
		right: -100%;

		.navigation--flyout {
			text-align: center;
		}

		ul.menu {
			& > li > a {
				font-size: 2rem;
			}
		}

		ul.sub-menu {
			margin: 0;
			
			li a {
				font-size: 1.25rem;
			}
		}
	}

	.form--search {
		width: 50%;
		margin: 0 auto;
	}

	.linkedin {
		margin: 0 auto;
	}
}