.footer--site {
	background-color: smd-color("light-grey");
	color: smd-color("dark-grey");

	a {
		color: smd-color("dark");
		text-decoration: underline;

		&:hover {
			color: smd-color("dark");
			text-decoration: none;
		}
	}
}

.footer__navigation {
	display: flex;
}

.sub-footer-nav {
	li {
		margin-bottom: 0.75rem;
	}
}

.link--linkedin {
	position: relative;
	display: inline-block;

	&::before {
		content: 'Follow SMD';
		display: block;
		position: absolute;
		white-space: nowrap;
		top: 50%;
		transform: translateY(-50%);
		transform-origin: top right;
		right: 100%;
		font-size: .75rem;
		font-style: italic;
		font-weight: 600;
		text-transform: uppercase;
		color: smd-color('grey');
		margin-right: 10px;
	}
}

@include media-breakpoint-down(md) {
	.link--linkedin {
		&::before {
			display: none;
		}
	}
}