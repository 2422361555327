form {
	.form-group {
		margin-bottom: 0;
		position: relative;
	}

	label {
		display: none !important;
	}

	input[type="text"],
	input[type="email"],
	input[type="search"],
	textarea {
		border: solid 2px #efefef;
		padding: 10px;
		width: 100% !important;
	}

	span.wpcf7-not-valid-tip {
		position: absolute;
		bottom: -17px;
		left: 0;
		color: red;
		font-size: 0.85rem;
		font-style: italic;
	}

	.gform_footer {
		text-align: right;
	}

	input[type="submit"] {
		@extend .btn;
		@extend .btn--no-arrow;
	}
}

.search-form-page {
	input[type="search"] {
		width: 75% !important;
	}
	input[type="submit"] {
		float: right;
	}
}

.grecaptcha-badge {
    display: none !important;
}