.pricing {
	.container {
		display: flex;
		flex-flow: row nowrap;
	}

	.left,
	.right {
		width: 50%;
		display: flex;
		flex-flow: column wrap;
	}

	.left {
		@extend .py-4;
		justify-content: center;
		position: relative;
		z-index: 1;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 50vw;
			background-color: $black;
			z-index: -1;
		}

		// &:after {
		// 	content: '';
		// 	display: block;
		// 	width: 16px;
		// 	height: 55px;
		// 	position: absolute;
		// 	top: 50%;
		// 	right: 1.975rem;
		// 	transform: translateY(-50%);
		// 	background-image: url('./assets/images/icon-arrow-grey-right.svg');
		// }

		.co-link {
			display: block;
			width: 16px;
			height: 55px;
			position: absolute;
			top: 50%;
			right: 1.975rem;
			transform: translateY(-50%);
			background-image: url('./assets/images/icon-arrow-grey-right.svg');
		}
	}

	.right {
		padding-top: 3rem;
		padding-left: 3rem;
		justify-content: center;
	}

	a.btn {
		align-self: flex-end;
	}
}

#glide--services {
	position: relative;

	&:after {
		content: '';
		display: block;
		width: var(--home-glide-peek);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		background-image: linear-gradient(90deg, rgba(255,255,255,0.00) 0%, #FFFFFF 60%);
		z-index: 0;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.glide__slides {
		.glide__slide {
			background-image: linear-gradient(90deg, #EEEEEE 0%, #FFFFFF 100%);
			padding: 1.5rem 2rem;

			a {
				text-decoration: underline;
			}

			a:hover {
				text-decoration: none;
			}
		}
	}

	.glide__arrows {
		.glide__arrow {
			position: absolute;
			top: 0;
			height: 100%;
			text-indent: -9999px;
			border: none;
			background-color: transparent;
			background-repeat: no-repeat;
			background-size: 16px 55px;
		}
		.glide__arrow--left {
			width: 16px;
			left: -3rem;
			background-image: url('./assets/images/icon-arrow-large-orange-left.svg');
			background-position: center;
		}
		.glide__arrow--right {
			width: var(--home-glide-peek);
			right: 0;
			background-image: url('./assets/images/icon-arrow-large-orange-right.svg');
			background-position: right center;
			z-index: 9;
		}
	}
}

@include media-breakpoint-down(lg) {
	.pricing {
		.container {
			flex-flow: column wrap;
		}

		.left,
		.right {
			width: 100%;
		}

		.left {
			&::before {
				width: 100vw;
				right: -2rem;
			}
		}

		.right {
			padding-left: 0;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

	#glide--services {
		.glide__arrows {
			display: none;
		}
	}
}