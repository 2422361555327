// Colors
$smd-colors: (
	"light-orange": #FEC834,
	"dark-orange": #FBB040,
	"dark": #222222,
	"grey": #777777,
	"dark-grey": #444444,
	"medium-grey": #DDDDDD,
	"light-grey": #ebebeb
);

@function smd-color($key: "dark-orange") {
  @return map-get($smd-colors, $key);
}

@each $color, $value in $smd-colors {
  .text-smd-#{$color} {color: $value !important;}
}

$link-color: smd-color("dark-orange");

$smd-grey-gradient: linear-gradient(90deg, #FFFFFF 0%, #EEEEEE 100%);
$smd-grey-gradient-reverse: linear-gradient(90deg, #EEEEEE 0%, #FFFFFF 100%);
$smd-orange-gradient: linear-gradient(90deg, smd-color('light-orange') 0%, smd-color('dark-orange') 100%);
$smd-orange-gradient-reverse: linear-gradient(90deg, smd-color('dark-orange') 0%, smd-color('light-orange') 100%);

// Typography
$line-height-base: 1.25;
$font-family-sans-serif: "OpenSans", "Helvetica Neue", Arial, sans-serif;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-weight-light: 300;

// Spacing
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
	(
		0: 0,
		1: ($spacer * .25),    //4px
		2: ($spacer * .5),     //8px
		3: $spacer,            //16px
		4: ($spacer * 1.5),    //24px
		5: ($spacer * 3),      //48px
		6: ($spacer * 4),      //64px
		7: ($spacer * 5),      //80px
		8: ($spacer * 6.25),   //100px
		9: ($spacer * 7.5),    //120px
		10: ($spacer * 9.375)  //150px
	),
	$spacers
);