@font-face {
	font-family: "OpenSans";
	src: url('./assets/fonts/OpenSans-Regular.eot');
	src: url('./assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
			 url('./assets/fonts/OpenSans-Regular.woff2') format('woff2'),
			 url('./assets/fonts/OpenSans-Regular.woff') format('woff'),
			 url('./assets/fonts/OpenSans-Regular.ttf') format('truetype'),
			 url('./assets/fonts/OpenSans-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "OpenSans";
	src: url('./assets/fonts/OpenSans-Italic.eot');
	src: url('./assets/fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
			 url('./assets/fonts/OpenSans-Italic.woff2') format('woff2'),
			 url('./assets/fonts/OpenSans-Italic.woff') format('woff'),
			 url('./assets/fonts/OpenSans-Italic.ttf') format('truetype'),
			 url('./assets/fonts/OpenSans-Italic.svg') format('svg');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: "OpenSans";
	src: url('./assets/fonts/OpenSans-Light.eot');
	src: url('./assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
			 url('./assets/fonts/OpenSans-Light.woff2') format('woff2'),
			 url('./assets/fonts/OpenSans-Light.woff') format('woff'),
			 url('./assets/fonts/OpenSans-Light.ttf') format('truetype'),
			 url('./assets/fonts/OpenSans-Light.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "OpenSans";
	src: url('./assets/fonts/OpenSans-LightItalic.eot');
	src: url('./assets/fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
			 url('./assets/fonts/OpenSans-LightItalic.woff2') format('woff2'),
			 url('./assets/fonts/OpenSans-LightItalic.woff') format('woff'),
			 url('./assets/fonts/OpenSans-LightItalic.ttf') format('truetype'),
			 url('./assets/fonts/OpenSans-LightItalic.svg') format('svg');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: "OpenSans";
	src: url('./assets/fonts/OpenSans-SemiBold.eot');
	src: url('./assets/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
			 url('./assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
			 url('./assets/fonts/OpenSans-SemiBold.woff') format('woff'),
			 url('./assets/fonts/OpenSans-SemiBold.ttf') format('truetype'),
			 url('./assets/fonts/OpenSans-SemiBold.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: "OpenSans";
	src: url('./assets/fonts/OpenSans-SemiBoldItalic.eot');
	src: url('./assets/fonts/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
			 url('./assets/fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
			 url('./assets/fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
			 url('./assets/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype'),
			 url('./assets/fonts/OpenSans-SemiBoldItalic.svg') format('svg');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: "OpenSans";
	src: url('./assets/fonts/OpenSans-Bold.eot');
	src: url('./assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
			 url('./assets/fonts/OpenSans-Bold.woff2') format('woff2'),
			 url('./assets/fonts/OpenSans-Bold.woff') format('woff'),
			 url('./assets/fonts/OpenSans-Bold.ttf') format('truetype'),
			 url('./assets/fonts/OpenSans-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "OpenSans";
	src: url('./assets/fonts/OpenSans-BoldItalic.eot');
	src: url('./assets/fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
			 url('./assets/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
			 url('./assets/fonts/OpenSans-BoldItalic.woff') format('woff'),
			 url('./assets/fonts/OpenSans-BoldItalic.ttf') format('truetype'),
			 url('./assets/fonts/OpenSans-BoldItalic.svg') format('svg');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: "OpenSans";
	src: url('./assets/fonts/OpenSans-ExtraBoldItalic.eot');
	src: url('./assets/fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
			 url('./assets/fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
			 url('./assets/fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
			 url('./assets/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
			 url('./assets/fonts/OpenSans-ExtraBoldItalic.svg') format('svg');
	font-weight: 800;
	font-style: italic;
}