.postette {
	.post__date {
		display: block;
		text-transform: uppercase;
		font-size: 0.875rem;
		color: smd-color("light-orange");
		margin-bottom: 1rem;
	}

	.post__title {
		color: smd-color("grey");
		font-style: normal;
		font-weight: 300;
		font-size: 1.375rem;
	}

	.post__excerpt {
		color: smd-color("dark-grey");
	}

	.post__link {
		text-align: right;
	}
}