html, body {
	height: 100%;
}

body {
	overflow-x: hidden;
}

button,
.btn,
button:focus,
.btn:focus {
	outline: none !important;
	box-shadow: none !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
.form-control:focus {
	outline: none !important;
}

:root {
	--home-glide-peek: 150px;
}

$text-sizes: (
	2xs: .6375rem,
	xs: .75rem,
	sm: .875rem,
	base: 1rem,
	lg: 1.25rem,
	xl: 1.5rem,
	2xl: 2rem,
	3xl: 3.5rem,
	4xl: 4rem,
	5xl: 5rem
);

@each $size, $rem in $text-sizes {
	.text-#{$size} {
		font-size: #{$rem} !important;
	}
}

img {
	max-width: 100%;
	display: block;
}

// Wordpress Editor Alignment Classes
.alignright {
	float:right;
	position: relative;
	right: -2.5rem;
	margin-bottom: 2rem;
}
.alignleft {
	float: left;
	position: relative;
	left: -2.5rem;
	margin-bottom: 2rem;
}
.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2rem;
}
a .alignright {
	float:right;
	margin:0 0 2rem 2rem
}
a .alignleft {
	float:left;
	margin:0 2rem 2rem 0
}
a .aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto
}
.wp-caption-text,
.wp-block-embed figcaption {
	margin: 0;
	text-align: center;
	font-size: 0.875rem;
	color: lighten(#000000, 50%);
	font-style: italic;
	padding: 0.5rem;
	background-color: #ffffff;
}
.block-editor-block-list__block[data-type="core/embed"][data-align="left"] .block-editor-block-list__block-edit,
.block-editor-block-list__block[data-type="core/embed"][data-align="right"] .block-editor-block-list__block-edit,
.wp-block-embed.alignleft,
.wp-block-embed.alignright {
	width: auto !important;
	max-width: initial;
}
.wp-block-image {
	img {
		width: 100%;
	}
}

@include media-breakpoint-up(lg) {
	body:not(.has-hero):not(.home) {
		padding-top: 12rem;
	}
}

@include media-breakpoint-down(lg) {
	html,body {
		font-size: 12px;
	}

	body:not(.has-hero):not(.home) {
		padding-top: var(--header-height);
	}

	.container {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.alignright {
		float: none;
		right: 0;
	}
	.alignleft {
		float: none;
		left: 0;
	}
}