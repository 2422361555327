h1 {
	font-weight: 300;
}

p {
	line-height: 1.5;
}

.bold {
	font-weight: bold;
}

.content--formatted {
	h1,h2,h3 {
		color: smd-color("light-orange");
		font-weight: 300;
		font-style: italic;
		margin-bottom: 2rem;
	}

	h6 {
		font-size: 0.875rem;
		color: smd-color("dark-orange");
		text-transform: uppercase;
		margin-bottom: .5rem;
	}

	p {
		margin-bottom: 2rem;
		line-height: 1.75;
	}
}