.main--page {
	padding-bottom: 5rem;

	.row {
		position: relative;
	}

	.page__title {
		position: relative;
		top: -10px;
		text-align: right;
		width: 100%;
		font-weight: 800;
		font-style: italic;
		font-size: 4.5rem;
		text-transform: uppercase;
		color: smd-color("medium-grey");
		line-height: 1;
		margin-bottom: 2rem;
	}

	aside {
		position: relative;
		z-index: 1;
		padding: 5rem 2rem 5rem 0;

		&::before {
			content: '';
			display: block;
			background-image: $smd-grey-gradient;
			top: 0;
			right: 0;
			width: 30vw;
			height: 100%;
			position: absolute;
			z-index: -1;
		}

		h6 {
			font-size: 0.875rem;
			color: smd-color("dark-orange");
			text-transform: uppercase;
			margin-bottom: 1.5rem;
		}
	}
}

@include media-breakpoint-down(md) {
	.main--page {
		aside {
			display: none;
		}
	}
}