.pagination {
	width: 100%;
	color: smd-color("dark-orange");
	font-weight: bold;
	font-size: 1.25rem;
	text-align: center;
	position: relative;
	border-top: solid 1px smd-color("light-grey");
	margin-top: 2rem;
	padding-top: 2rem;

	a {
		color: smd-color("dark-orange");
	}

	.page-numbers {
		padding: 0 .5rem;
	}

	.page-numbers.current {
		color: smd-color("dark-grey");
	}

	a.next {
		position: absolute;
		right: 0;
		top: 2rem;
	}
	a.prev {
		position: absolute;
		left: 0;
		top: 2rem;
	}
}