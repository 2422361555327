.btn,
button {
	padding: 0;
}

.btn {
	display: inline-block;
	background-image: $smd-orange-gradient;
	border: 2px solid smd-color("light-orange");
	font-weight: 700;
	padding: .5rem 3rem .5rem .75rem;
	text-transform: uppercase;
	position: relative;
	transform: skew(-15deg);
	color: smd-color("dark");
	letter-spacing: .5px;

	&::after {
		content: '';
		display: block;
		position: absolute;
		width: 22px;
		height: 9px;
		top: 50%;
		right: 1rem;
		transform: translateY(-50%);
		background-image: url('./assets/images/icon-arrow-right.svg');
	}

	&:hover {
		color: smd-color("dark");
		text-decoration: underline;
	}
}

.btn--no-arrow {
	padding-right: .75rem !important;

	&::after {
		display: none;
	}
}

.btn--large {
	padding: .625rem 3rem .625rem .75rem;
}

.btn--small {
	padding: .375rem 3rem .375rem .75rem;
	font-size: 0.875rem;
}

.btn--dark {
	@extend .text-sm;
	background-image: none;
	background-color: smd-color("dark");
	border: none;
	color: smd-color("dark-orange");

	&:after {
		background-image: url('./assets/images/icon-arrow-orange-right.svg');
	}

	&:hover {
		color: smd-color("dark-orange");
	}
}

