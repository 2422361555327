.hero--home {
	padding-top: calc(var(--header-height) + 17%);
	height: 100vh;
	//background-image: url('./assets/images/HOME_Hero-CPU.png');
	background-image: url('./assets/images/hero.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	color: smd-color('dark-grey');
}

.hero--page {
	padding-top: var(--header-height);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	min-height: 550px;
	position: relative;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;

	.hero__caption {
		font-weight: 300;
		font-style: italic;
		font-size: 2.25rem;
	}
}

body.dark-mode {
	.hero--page {
		.hero__caption {
			color: $white;
		}
	}
}

@include media-breakpoint-down(md) {
	.hero--home {
		background-size: 100%;
		background-position: bottom;
		padding-top: calc(var(--header-height) + 10vh);
	}

	.hero--page {
		min-height: 300px
	}
}

@include media-breakpoint-down(xs) {
	.hero--home {
		background-size: 175%;
		padding-top: calc(54px + 10vh);
	}
}
