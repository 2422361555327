.header--site {
	@include animate;
	position: absolute;
	top: 0;
	width: 100%;
	padding-top: 3.75rem;
	padding-bottom: 2rem;
	overflow: hidden;
	z-index: 999;

	.container {
		display: flex;
		align-items: center;
	}

	.btn--toggle-menu {
		display: block;
		position: relative;
		padding: 0.75rem;
		font-size: 0.75rem;
		margin-left: 1.5rem;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 1px;
		z-index: 1;
		color: #222222;

		&::before {
			content: '';
			display: block;
			width: 16px;
			height: 16px;
			background-image: url('./assets/images/icon-menu.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			float: left;
			margin-right: 1rem;
		}

		&::after {
			content: '';
			display: block;
			width: 9999px;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			background-image: $smd-orange-gradient-reverse;
			z-index: -1;
		}

		&:hover {
			color: #222222;
		}
	}
}

.logo--site {
	display: block;
	text-indent: -9999px;
	width: 190px;
	height: 33px;
	background-image: url('./assets/images/logo-color.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.header--site.sticky {
	position: fixed;
	background-color: $white;
	box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
	padding: 1rem 0;

	.logo--site {
		width: 130px;
	}

	.btn--toggle-menu {
		background-color: smd-color('light-orange');

		&::after {
			display: none;
		}
	}
}

@include media-breakpoint-up(lg) {
	body.dark-mode {
		.header--site:not(.sticky) {
			.logo--site {
				background-image: url('./assets/images/logo-white.svg');
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.header--site,
	.header--site.sticky {
		position: fixed;
		padding-top: 0;
		padding-bottom: 0;
		background-color: smd-color('light-orange');

		.container {
			padding-right: 0;
		}

		.logo--site {
			width: 112px;
			background-image: url('./assets/images/logo-black.svg');
		}

		.btn--toggle-menu {
			padding: 1.25rem;
			height: 100%;
			background-image: $smd-orange-gradient-reverse;
			text-indent: -9999px;
			margin-left: auto;

			&::before {
				width: 24px;
				height: 24px;
				margin: 0;
			}

			&::after {
				display: none;
			}
		}
	}
}