.contact--page {
	padding-bottom: 5rem;

	.row {
		position: relative;
	}

	.page__title {
		position: relative;
		top: -10px;
		text-align: right;
		width: 100%;
		font-weight: 800;
		font-style: italic;
		font-size: 4.5rem;
		text-transform: uppercase;
		color: smd-color("medium-grey");
		line-height: 1;
		margin-bottom: 2rem;
	}

	aside {
		h5 {
			font-size: 2rem;
			font-weight: 300;
			color: smd-color('grey');
		}

		h6 {
			font-size: 0.875rem;
			color: smd-color("dark-orange");
			text-transform: uppercase;
			margin-bottom: .5rem;
		}

		.phone, .email, .address {
			color: smd-color('grey');
		}
	}
}