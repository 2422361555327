.posts {
	column-count: 2;
	column-gap: 6rem;

	.postette {
		margin-bottom: 3rem;
		break-inside: avoid;
	}
}

@include media-breakpoint-down(md) {
	.posts {
		column-count: 1;
		column-gap: 0;
	}
}