/*!
 * Theme Name: SMD OKC
 * Theme URI: https://smdokc.com;
 * Author: Cosby Harrison Marketing
 * Author URI: https://www.chcmarketing.com
 * Description: Surface Mount Depot Wordpress Theme
 * Version: 1.0
 * License: GNU General Public License v2 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 * Text Domain: smdokc
 */

// Local variables (allows for Bootstrap variable overrides)
@import 'fonts';
@import 'vars';

// Bootsrap parts
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/utilities";

// Universal
@import 'global';
@import 'mixins';

// Layout
@import 'layout/header';
@import 'layout/footer';
@import 'layout/navigation';
@import 'layout/navigation-flyout';
@import 'layout/content';

// Elements
@import 'elements/buttons';
@import 'elements/typography';
@import 'elements/hero';
@import 'elements/callouts';
@import 'elements/postette';
@import 'elements/pagination';
@import 'elements/forms';

// View Specific
@import 'views/home';
@import 'views/posts';
@import 'views/people';
@import 'views/contact';

// Libraries
@import 'libs/glide';