.navigation--header {
	@extend .ml-auto;
	display: flex;
	align-items: right;

	ul {
		@extend .m-0;
		@extend .list-unstyled;
		display: flex;
		align-items: right;

		li a {
			display: block;
			color: #777777;
			padding: 0 0.9375rem;
			font-weight: 700;
			font-style: italic;
			text-transform: uppercase;
		}
	}
}

.navigation--footer {
	margin-left: auto;

	ul {
		display: flex;

		li {
			margin: 0 .5rem;

			a {
				font-size: 0.875rem;
				font-weight: 600;
				font-style: italic;
				letter-spacing: .5px;
				text-transform: uppercase;
				text-decoration: none;
			}

			a:hover {
				text-decoration: underline;
			}
		}
	}
}

.navigation--sidebar {
	li {
		margin-bottom: 0.875rem;

		a {
			font-style: italic;
			font-size: 1.15rem;
			color: smd-color("grey");
		}

		&.current_page_item a {
			color: $black;
			text-decoration: underline;
		}
	}	
}

.navigation--sub-pages {
	display: flex;
	justify-content: space-between;
	flex-flow: row wrap;
	transform: rotate(180deg);

	li {
		width: 48%;
		margin-bottom: 3rem;
		position: relative;
		transform: rotate(-180deg);

		span {
			display: block;
			text-align: center;
			background-image: url('./assets/images/service-bg.png');
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			padding: 3rem 0;
			color: $white;
			font-weight: 500;
			font-style: italic;
			font-size: 1.25rem;
		}

		a {
			position: absolute;
			bottom: -1rem;
			right: 1rem;
		}
	}
}

.navigation--posts {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	margin-top: 2rem;
	padding-top: 2rem;
	border-top: solid 1px smd-color('light-grey');

	.postette {
		width: 35%;

		img {
			display: none;
		}
	}
}

body.dark-mode {
	.header--site:not(.sticky) {
		.navigation--header {
			ul li a {
				color: $white;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.navigation--header {
		display: none;
	}

	.navigation--footer {
		display: none;
	}

	.navigation--sub-pages {
		li {
			width: 100%;
		}
	}

	.navigation--posts {
		flex-flow: column wrap;
		
		.postette {
			width: 100%;
		}
	}
}